import Columns from '@components/CRUD/Item/Columns';
import ColumnItem from '@components/CRUD/Item/ColumnItem';
import { store } from '@components/CRUD/Item/store';

import { googleMapsLink } from '@utilities/functions';
import { countries } from '@utilities/variables';
import { notifySuccess, notifyError } from '@components/Notification';


export default {
    store,


    computed: {
        options:  function () {
            return this.$store.state.options; },
        item:     function () {
            return this.$store.state.data; },
        isLoaded: function () {
            return this.$store.state.isLoaded; },
        api:      function () {
            return this.$store.state.api; },
        itemApi: function () { return this.$store.state.api && this.item ? '/api/' + this.$store.state.api + '/' + this.item.id : '' },
    },


    methods: {

        deleteItem: function (key, item) {
            if (confirm('You really want to delete this item?')) {
                return this.$store.dispatch('deleteProperty', { key, id: item.id })
                    .then(() => this.notifySuccess('Item deleted successfully'),
                        error => this.notifyError('Error while deleting item'));
            }
        },

        openForm: function (data = {}) {
            this.$refs.form.open(data);
        },

        // Item helper functions
        googleMapsLink: function (item) {
            return googleMapsLink(item);
        },

        country: function (item) {
            const label = item.country;
            const country = countries && countries.find(c => c.label === label);
            return country && country.name;
        },

        notifySuccess,
        notifyError
    },


    components: {
        Columns,
        ColumnItem
    }
};
