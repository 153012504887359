var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", [
    _c("th", [
      !_vm.isEmailForm
        ? _c(
            "label",
            { staticClass: "label", attrs: { for: "_importChecked" } },
            [_vm._v("Übersetzungen:")]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("td", [
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          {
            staticClass: "column",
            staticStyle: { "flex-direction": "row", display: "block" },
          },
          [
            _vm._l(_vm.translations, function (data, translation) {
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.changeLocale(translation)
                      },
                    },
                  },
                  [
                    _c("gb-flag", {
                      class: { "is-selected": translation === _vm.value },
                      attrs: {
                        code: _vm.getFlag(translation),
                        size: "small",
                        iconPath: "/assets/img/flags/",
                      },
                    }),
                  ],
                  1
                ),
              ]
            }),
            _vm._v(" "),
            !_vm.visible && !_vm.isEmailForm
              ? _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.visible = !_vm.visible
                      },
                    },
                  },
                  [_vm._v("add_circle")]
                )
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _vm.visible
          ? _c(
              "div",
              { staticClass: "column is-two-thirds" },
              [
                _c("multiselect", {
                  attrs: {
                    options: _vm.filteredTranslationOptions,
                    "": _vm.newLocale,
                    placeholder: "Neue Übersetzung wählen",
                    label: "name",
                    "track-by": "id",
                    "allow-empty": true,
                  },
                  on: { select: _vm.handleSelect },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }