import has from 'lodash/has';
import { Multiselect, AsyncSelect, ErrorWrapper } from '@components/form';
import ModalForm from '@components/ModalForm';
import { notifySuccess, notifyError } from '@components/Notification';
import { store } from '../store';

export default {
    store,

    form: {},
    property: '',

    props: {
        initialValue: {
            type: Object,
            required: false
        }
    },

    data: function () {
        return {
            visible: false,
            isLoading: false,

            form: {},
            errors: {}
        };
    },

    mounted: function () {
        if (this.initialValue) {
            this.handleInitialValue(this.initialValue);
        }

        this.$children[0].$on('submit', function () {
            this.handleSubmit();
        }.bind(this));

        this.$children[0].$on('closeModal', function () {
            this.close();
        }.bind(this));
    },

    computed: {
        options: function () {
            const options = this.initialValue && this.initialValue.options ? this.initialValue.options : this.$store.state.options;
            return has(options, this.$options.property) ? options[this.$options.property] : options;
        },
        resources: function () {
            return this.$store.state.resources;
        },
        editMode: function () {
            return !!this.form.id;
        },
        item: function () {
            return this.$store.state.data;
        },
    },

    methods: {
        handleInitialValue: function () {},

        open: function (item = {}) {
            this.visible = true;

            const emptyForm = JSON.parse(JSON.stringify(this.$options.form));
            const itemForm = JSON.parse(JSON.stringify(item));

            this.form = { ...emptyForm, ...itemForm };
            this.errors = {};
            this.openCallback(this.form);
        },

        openCallback() {},

        close: function () {
            this.visible = false;
            this.$emit('closeModal');
        },

        handleSubmit: function () {
            this.handleFormSubmit();
        },

        handleFormSubmit: function () {
            this.isLoading = true;
            this.$store.dispatch('updateProperty', {
                key: this.$options.property,
                data: this.form,
                ...this.locale && { locale: this.locale }

            })
                .then(data => {
                    this.visible = false;
                    notifySuccess('Das Element wurde erfolgreich hinzugefügt!');
                    this.$emit('success', {
                        form: this.form,
                        data
                    });
                    this.isLoading = false;
                }, error => {
                    this.isLoading = false;
                    notifyError('Das Element konnte nicht hinzugefügt werden! Server Error!');
                });
        },

        updateOptions: function (options) {
            this.$store.commit('updateOptions', {
                [this.$options.property]: options
            });
        },

        notifyError,
        notifySuccess
    },

    components: {
        Multiselect,
        AsyncSelect,
        ErrorWrapper,
        ModalForm
    }
};
