import Vuex from 'vuex';
import has from 'lodash/has';
import axios from 'axios';
import {getStorageTab, prepareForBackend} from '@utilities/functions';

const store = new Vuex.Store({
    state: {
        api: '',
        visitedTabs: [],
        propertyApis: {
            accountPlanItems : {
                api: 'account_plan_items',
                params: {
                    _groups: ['account_plan_item', 'translations']
                },
                update: false,
            },
            addresses : {
                api: 'addresses',
                params: {
                    _groups: ['contact_write', 'address_write_relations', 'port_read', 'airport_read', 'contact_read_relations', 'place_read']
                },
                softDelete: true,
                update: true,
            },
            organisationBankAccounts: {
                api: 'organisation_bank_accounts',
                params: {
                    _groups: ['organisation_bank_account', 'organisation:organisation_bank_account', 'bank', 'organisation_bank_account:account_plan_item', 'account_plan_item', 'check_book']
                },
                update: true,
            },
            contacts : {
                api: 'contacts',
                params: {
                    _groups: ['address_read', 'contact_read_relations']
                },
                softDelete: true,
                update: true,
            },
            bankAccounts: {
                api: 'bank_accounts',
                params: {},
                softDelete: true,
                update: true,
            },
            clientDates: {
                api: 'client_dates',
                params: {},
                update: true,
            },
            itemPrices: {
                api: 'provider_item_prices',
                params: {},
                update: true,
            },
            infos: {
                api: 'infos',
                params: {
                    _groups: ['image_read', 'agency_read_list']
                },
                update: true,
            },
            menus: {
                api: 'menus',
                params: {
                    _groups: ['provider:menu', 'menu:provider', 'menu', 'translations', 'menu:provider_other_service', 'other_service_write', 'other_service_type_read', 'provider_other_service:menu'],
                },
                update: true,
            },
            providerTripDetails: {
                api: 'provider_trip_details',
                params: {},
                update: true,
            },
            todoTemplates: {
                api: 'todo_templates',
                params: {
                    _groups: ['todo_template']
                },
                update: true,
            }
        },

        id: '',
        data: {},
        activeKey: getStorageTab(window.location.pathname),

        isLoaded: false,
        is404: false,

        options: {},
        isLoading: false,
    },

    mutations: {
        updateApi(state, newApi) {
            state.api = newApi;
        },

        updateData(state, newData) {
            const data = JSON.parse(JSON.stringify(newData));
            state.data = { ...state.data, ...data };

            if (has(data, 'id')) {
                state.id = data.id;
            }
        },

        updateDataElement(state, { key, data, isNew = false }) {
            const newItem = JSON.parse(JSON.stringify(data));

            if (!isNew && state.data[key].find(item => item.id === newItem.id)) {
                state.data = {
                    ...state.data,
                    [key]: state.data[key].map(item => ({
                        ...item,
                        ...item.id === newItem.id && { ...newItem }
                    }))
                };
            } else {
                newItem.key = newItem.id;
                state.data[key].push(newItem);
            }

        },

        setEditMode(state, { property, id = null, value = true }) {
            state.data = {
                ...state.data,
                [property]: id ? state.data[property].map(item => {
                    if (item.id === id) {
                        return { ...item, editMode: value };
                    }

                    return item;
                }) : state.data[property].filter(item => item.id)
            };
        },

        updateID(state, newID) {
            state.id = newID;
        },

        setLoaded(state, value) {
            state.isLoaded = value;
        },

        set404(state, value) {
            state.is404 = value;
        },

        updateOptions(state, newOptions) {
            const options = JSON.parse(JSON.stringify(newOptions));

            state.options = { ...state.options, ...options };
        },

        updateSequence: function (state, { key, sequence }) {
            state.data = {
                ...state.data,
                [key]: [...state.data[key]]
                    .map(item => {
                        if (sequence.includes(item.id)) {
                            return {
                                ...item,
                                positionInDocument: sequence.findIndex(id => id === item.id)
                            };
                        } else {
                            return item;
                        }
                    })
            };
        }
    },

    actions: {
        fetchItem({ state, commit }, { _groups, activeKey }) {
            state.isLoading = true;
            axios.get(`/api/${state.api}/${state.id}`, {
                params: {
                    _groups
                }
            })
                .then(response => {
                    state.visitedTabs.push(activeKey);
                    commit('updateData', response.data);
                    commit('setLoaded', true);
                    state.isLoading = false;
                }, error => {
                    commit('setLoaded', true);
                    commit('set404', true);

                });
        },

        fetchOptions({ state, commit }) {
            axios.options(`/api/${state.api}`)
                .then(response => {
                    commit('updateOptions', response.data);
                });
        },

        delete: function ({ state }) {
            return axios.delete(`/api/${state.api}/${state.id}`);
        },

        updateProperty: function ({ state, dispatch }, { key, data, locale = null }) {

            const newItem = JSON.parse(JSON.stringify(data));
            let items = state.data[key] !== undefined ? JSON.parse(JSON.stringify(state.data[key])) : [];


            if (key === 'contacts' && data.type === 'primary') {
                items = items.map(item => ({ ...item, type: null }));
            } else if (key === 'bankAccounts' && data.primaryAccount) {
                items = items.map(item => ({ ...item, primaryAccount: false }));
            }

            if (has(newItem, 'id')) {
                items = items.filter(item => item.id !== newItem.id);
            }

            let api = state.propertyApis[key];
            if (api) {
                items = items.map(item => (item.id ? '/api/' + api.api + '/' + item.id : item));
            }
            items.push(newItem);

            if (!api || !has(newItem, 'id')) {
                return dispatch('storeProperty', {
                    key,
                    data: items,
                    locale: locale,
                });
            } else {
                return dispatch('storePropertyWithApi', {
                    key,
                    data: newItem,
                    update: api.update,
                    locale: locale,
                });
            }

        },

        deleteProperty: function ({ state, dispatch }, { key, id }) {
            let api = state.propertyApis[key];

            if (!api) {
                return dispatch('storeProperty', {
                    key,
                    data: Array.isArray(id)
                        ? state.data[key].filter(item => !id.includes(item.id)).map(item => (api && item.id ? '/api/' + api.api + '/' + item.id : item))
                        : state.data[key].filter(item => item.id !== id).map(item => (api && item.id ? '/api/' + api.api + '/' + item.id : item))
                });
            } else {
                return dispatch('deletePropertyWithApi', {
                    key,
                    data: id
                });
            }
        },
        deletePropertyWithApi: function ({ state, commit }, { key, data }) {
            const softDelete = state.propertyApis[key].softDelete;
            const method = softDelete ? 'put' : 'delete';
            const url = softDelete ? `/api/${state.propertyApis[key].api}/${data}/delete` : `/api/${state.propertyApis[key].api}/${data}`;
            return new Promise((resolve, reject) => {
                axios[method](url, softDelete ? { deleted: true } : null)
                    .then(response => {
                        let deleteIndex = state.data[key].findIndex(item => item.id === data);
                        if (deleteIndex !== -1) {
                            state.data[key].splice(deleteIndex, 1);
                        }
                        resolve(state.data[key]);
                    }, error => { reject(error); });
            });
        },

        storePropertyWithApi: function ({ state, commit }, { key, data, update = true, locale = null }) {
            return new Promise((resolve, reject) => {
                axios.put(`/api/${state.propertyApis[key].api}/${data.id}`, prepareForBackend(data), {
                    params: state.propertyApis[key].params,
                    ...locale && {
                        headers: {
                            'X-LOCALE': locale
                        }
                    }
                })
                    .then(response => {
                        if (update) {
                            commit('updateDataElement', { key: key, data: response.data });
                        }
                        resolve(response.data);
                    }, error => { reject(error); });
            });
        },

        storeProperty: function ({ state, commit }, { key, data, update = true, locale = null }) {

            return new Promise((resolve, reject) => {
                axios.put(`/api/${state.api}/${state.id}`, prepareForBackend({
                    [key]: data
                }), {
                    params: {
                        _groups: [
                            ...(key === 'publishedHotelIds') || (key === 'webflowLinks') || ((key === 'readyForStaging')) || (key === 'pricesForHomepage') || (key === 'webflowLinks') ? ['webflow'] : [],
                            ...(['webflowLinks', 'publishedHotelIds', 'pricesForHomepage', 'homepageServices', 'hasJoinedBookings'].includes(key) && state.api === 'order_concepts' ? ['concept_order:webflow', 'webflow:concept_day'] : []),
                            ...(['webflowLinks', 'readyForStaging'].includes(key) && state.api === 'day_concepts' ? ['day_order:webflow'] : []),
                            ...(['webflowLinks', 'readyForStaging'].includes(key) && state.api === 'hotels' ? ['provider:webflow'] : []),
                            ...(['checklistItems'].includes(key) && state.api === 'destinations' ? ['destination:order_checklist_item', 'order_checklist_item'] : []),
                            ...(['checklistItems'].includes(key) && state.api === 'day_concepts' ? ['concept_day:order_checklist_item', 'order_checklist_item'] : []),
                            ...(['checklistItems'].includes(key) && state.api === 'order_concepts' ? ['concept_order:order_checklist_item', 'order_checklist_item'] : []),
                            ...(['checklistItems'].includes(key) && state.api === 'hotels' ? ['provider:order_checklist_item', 'order_checklist_item'] : []),
                            ...(key === 'user' && state.api === 'order_concepts' ? ['concept_order:user', 'user_read'] : []),
                            ...(key === 'conditions' ? ['provider_condition_write', 'provider_condition_read'] : []),
                            ...(key === 'bankAccounts' ? ['bank_account_write', 'bank_account_read'] : []),
                            ...(key === 'freeplaces' ? ['provider_freeplace_write', 'provider_freeplace_read'] : []),
                            ...(key === 'prices' ? ['provider_hotel_price_write', 'order_contingent_item_write'] : []),
                            ...(key === 'itemPrices' ? ['item_price_write'] : []),
                            ...(key === 'addresses' ? ['address_write', 'organisation:address', 'contact_write', 'address_write_relations', 'port_read', 'airport_read', 'contact_read_relations', 'place_read'] : []),
                            ...(key === 'accountPlanItems' ? ['account_plan_item', 'organisation:account_plan_item'] : []),
                            ...(key === 'organisationBankAccounts' ? ['organisation_bank_account', 'organisation:organisation_bank_account', 'organisation_bank_account:account_plan_item', 'check_book', 'account_plan_item', 'bank'] : []),
                            ...(key === 'routeDetails' ? ['order_route_predefined_write'] : []),
                            ...(key === 'mainUser' ? ['user_read', 'user_write'] : []),
                            ...(key === 'mainUsers' ? ['user_read', 'user_write'] : []),
                            ...(key === 'contacts' ? ['contact_write', 'address_write', 'contact_read_relations', 'contact_write_relations'] : []),
                            ...(key === 'providerTripDetails' ? ['provider_trip_detail_write', 'info_write', 'image_read'] : []),
                            ...(key === 'infos' ? ['provider_trip_detail_write', 'image_read', 'info_write'] : []),
                            ...(key === 'menus' ? ['provider:menu', 'menu', 'translations', 'menu:provider_other_service', 'other_service_write', 'other_service_type_read', 'provider_other_service:menu'] : []),
                            ...(key === 'clientVisits' ? ['user_write', 'client:client_visit', 'client_visit', 'client_visit:user', 'client_visit:contact'] : []),
                            ...(key === 'imageFolders' ? ['image_folder_write', 'concept_order:image_folder'] : []),
                            ...(key === 'accountPlanItems' ? ['translations'] : []),
                            ...(key === 'days' ? ['concept_order:days', 'concept_day_read'] : []),
                            ...(key === 'todoTemplates' ? ['provider:todo_template', 'todo_template'] : []),

                        ]
                    },
                    ...locale && {
                        headers: {
                            'X-LOCALE': locale
                        }
                    }
                }
                )
                    .then(response => {
                        if (update) {
                            commit('updateData', {
                                [key]: response.data[key]
                            });
                        }
                        resolve(response.data);
                    }, error => {
                        reject(error);
                    });
            });
        },
    },

    getters: {
        isMuseum: function (state) {
            if (state.api === 'others') {
                return ['museum', 'musem'].includes(state.data.type);
            }
            return false;
        },
    }
});

export { store };
export default store;
